import { css } from '@emotion/react'
import { globalHistory } from '@reach/router'
import { rgba } from 'polished'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { useLightboxContext } from '@/contexts/lightbox-context'
import { ScrollToggle } from '@/features/common-blocks'
import { SeoMetaTags } from '@/features/layout'
import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { useFocusTrap } from '@/hooks/useFocusTrap'
import { bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { LightboxContent, LightboxContentData } from './LightboxContent'

type Props = {
  data: LightboxContentData | null
  isOpen: boolean
  onClose: () => void
  entry: {
    title: string
    path: string
  } | null
  layout?: 'FULL' | 'CENTERED' | 'VIDEO'
  slug: string
}

export const Lightbox = ({
  data,
  isOpen,
  onClose = () => null,
  entry,
  slug,
  layout = 'CENTERED',
}: Props): JSX.Element => {
  const portalTarget =
    typeof window !== `undefined` &&
    document.getElementById('lightbox-container')

  const [closing, setClosing] = useState(false)
  const { setLightboxOpen } = useLightboxContext()

  const title = (
    (data?.seoMetaTags as unknown as SeoMetaTags)?.tags.find(
      tag => tag.tagName === 'title' || {}
    ) as {
      content: string
    }
  ).content

  useEffect(() => {
    if (isOpen && !closing) {
      title && (document.title = title)
      setTimeout(() => {
        window.history.pushState({ title: title }, '', slug)
      }, 10)
    }
  }, [isOpen, closing, slug, title])

  useEffect(() => {
    if (isOpen) {
      setLightboxOpen(true)
    }
    if (closing) {
      setLightboxOpen(false)
    }
    return () => {
      setLightboxOpen(false)
    }
  }, [setLightboxOpen, isOpen, closing])

  const [lightboxRef, setLightboxRef] = useState<HTMLDivElement | null>(
    null
  )
  useFocusTrap({ elements: [lightboxRef], condition: isOpen })

  const transitionDuration = 300

  const handleCloseTransition = useCallback(() => {
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      onClose()
    }, transitionDuration)
  }, [onClose])

  const handleClose = useCallback(() => {
    if (!closing) {
      window.history.back()
    }
  }, [closing])

  useEffect(() => {
    if (isOpen) {
      globalHistory.listen(({ action }) => {
        // if new page is opened via Link
        if (action === 'PUSH') {
          handleCloseTransition()
        }
        // if lightbox is closed or history.back()
        if (action === 'POP') {
          handleCloseTransition()
          setTimeout(() => {
            if (entry) {
              document.title = entry.title
            }
          }, 10)
        }
      })
    }
  }, [handleCloseTransition, entry, isOpen])

  // used to trigger animations
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setLoaded(true)
      }, 10)
    } else {
      setLoaded(false)
    }
  }, [isOpen])

  useEscKeyFunction(isOpen ? handleClose : () => null)

  const styles = {
    background: css`
      position: fixed;
      width: 100vw;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 11;
      transition:
        background-color ${transitionDuration}ms ease,
        backdrop-filter 0ms linear ${transitionDuration}ms;
      ${loaded &&
      css`
        background-color: ${rgba(colors.navy, 0.9)};
        backdrop-filter: blur(0.333rem) saturate(0);
      `}
      ${(closing || !loaded) &&
      css`
        background-color: transparent;
        backdrop-filter: blur(0);
      `}
      ${closing &&
      css`
        transition-delay: 0ms;
      `}
    `,
    lightbox: css`
      display: grid;
      --vertical-margin: 1em;
      --lightbox-margin: var(--margin);
      grid-template-columns: var(--lightbox-margin) 1fr var(
          --lightbox-margin
        );
      grid-template-rows: var(--vertical-margin) auto var(
          --vertical-margin
        );
      box-sizing: border-box;
      position: fixed;
      top: 0;
      left: 0;
      overflow-y: scroll;
      width: 100vw;
      height: 100%;
      z-index: 12;
      transition:
        opacity ${transitionDuration}ms ease,
        transform ${transitionDuration}ms ease;
      ${mq().s} {
        --vertical-margin: 0.5rem;
        --lightbox-margin: 0.5rem;
      }
      ${layout === 'FULL' &&
      css`
        --lightbox-margin: calc(var(--margin) * 1.5);
        --vertical-margin: 0;
        --grid-w: calc(100vw - var(--lightbox-margin));
        grid-template-columns: 0fr 1fr var(--lightbox-margin);
        ${mq().s} {
          --vertical-margin: 0;
        }
      `}
      ${loaded &&
      css`
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `}
      ${(closing || !loaded) &&
      css`
        opacity: 0;
        ${layout === 'FULL' &&
        css`
          transform: translate3d(-6rem, 0, 0);
        `}
      `}
    `,
    content: css`
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      justify-self: center;
      align-self: center;
      display: grid;
      position: relative;
      ${layout === 'FULL' &&
      css`
        justify-self: stretch;
      `}
    `,
    closeButton: css`
      grid-area: 1 / 1 / 2 / 2;
      position: sticky;
      flex: 0;
      align-self: flex-start;
      justify-self: flex-end;
      top: 0.5em;
      display: flex;
      padding: 0.5em;
      margin-top: 0.5em;
      margin-right: 0.5em;
      margin-left: max(calc(var(--margin) - 2.75em), 0px);
      color: ${colors.navyLight};
      transition: color 200ms ease;
      svg {
        width: 1.25em;
        height: auto;
        transition: transform 300ms ${bezier.bounce};
        overflow: visible;
        line {
          fill: none;
          stroke: currentColor;
          stroke-width: 3;
        }
      }
      @media (hover: hover) {
        &:hover {
          color: ${colors.navy};
          svg {
            transform: scale3d(1.15, 1.15, 1);
          }
        }
      }
      ${mq().s} {
        padding: 0.25em;
        width: 1.75em;
        height: 1.75em;
      }
      ${layout === 'VIDEO' &&
      css`
        transform: translateX(calc(100% + 1em));
        @media (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
        ${mq().m} {
          transform: translate(0.5em, calc(-100% - 1em));
        }
      `}
    `,
    backgroundClose: css`
      position: relative;
      grid-row: 1 / 4;
      grid-column: 1 / -1;
      z-index: 0;
      ${closing &&
      css`
        display: none;
      `}
    `,
  }

  if (isOpen && portalTarget) {
    return createPortal(
      <Fragment>
        <ScrollToggle />
        <div css={styles.background} />
        <div
          css={styles.lightbox}
          ref={node => setLightboxRef(node)}
        >
          <div
            css={styles.backgroundClose}
            onClick={handleClose}
            aria-hidden
          />
          <div css={styles.content}>
            <button
              aria-hidden
              tabIndex={-1}
              style={{ width: 0, height: 0 }}
            />
            <LightboxContent data={data} />
            <button
              css={styles.closeButton}
              aria-label="Close Lightbox"
              onClick={handleClose}
              onKeyPress={handleClose}
            >
              <svg viewBox="0 0 12 12">
                <line
                  x1="0.5"
                  y1="0.5"
                  x2="11.5"
                  y2="11.5"
                  vectorEffect="non-scaling-stroke"
                />
                <line
                  x1="11.5"
                  y1="0.5"
                  x2="0.5"
                  y2="11.5"
                  vectorEffect="non-scaling-stroke"
                />
              </svg>
            </button>
          </div>
        </div>
      </Fragment>,
      portalTarget
    )
  }
  return <Fragment />
}
